import { useLayoutEffect, useRef, useState } from "react";
import { TextWrapperProps } from "./types";
import { Tooltip } from "../tooltip";
import { Typography } from "../typography";

export const TextWrapper = ({
  content,
  fontSize,
  fontWeight,
  className,
  color,
}: TextWrapperProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [shouldTextBeWrapped, setShouldTextBeWrapped] = useState(false);

  useLayoutEffect(() => {
    const parentWidth = ref.current?.clientWidth || 0;
    let childrenWidth = 0;
    ref.current?.childNodes.forEach(node => {
      childrenWidth += (node as HTMLDivElement).clientWidth;
    });
    const shouldBeWrapped = parentWidth <= childrenWidth;
    if (shouldBeWrapped) {
      setShouldTextBeWrapped(true);
    }
  }, []);

  return (
    <div className="d-flex w-100 align-items-center" ref={ref}>
      {shouldTextBeWrapped ? (
        <Tooltip title={content}>
          <div className="d-flex align-items-center gap-1 w-100">
            <Typography
              className={className}
              color={color || "neutralBlack100"}
              fontSize={fontSize}
              fontWeight={fontWeight}
              noWrap
            >
              {content}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography
          className={className}
          color={color || "neutralBlack100"}
          fontSize={fontSize}
          fontWeight={fontWeight}
          noWrap
        >
          {content}
        </Typography>
      )}
    </div>
  );
};
